import type { RecursivePartial } from '#types/utils'
import type { AccountConfig } from '#types/config/pages/account'
import { AccountNavItemType } from '#root/enums/account'

export default {
  navigation: [
    AccountNavItemType.myAccount,
    AccountNavItemType.myProfile,
    AccountNavItemType.myOrders,
    AccountNavItemType.addressBook,
    AccountNavItemType.giftCards,
    AccountNavItemType.creditCards,
    AccountNavItemType.favorites,
    AccountNavItemType.memberRewards
  ] as unknown as AccountConfig['navigation'],
  brandClasses: {
    heading: 'mt-10 md:mt-8 title-1',
    navItem: 'hover:(fw-bold underlined)',
    navItemActive: 'underlined fw-bold'
  },
  brandStyles: {
    container: {
      'max-w': {
        sm: '52.5rem'
      }
    }
  },
  address: {
    brandClasses: {
      formContainer: 'grid pb-16 md:cols-2'
    },
    dialogOptions: { type: 'modal' }
  },
  creditCards: {
    brandClasses: {
      formContainer: 'grid pb-16 md:cols-2'
    },
    dialogOptions: { type: 'modal' }
  },
  orderHistory: {
    brandClasses: {
      grid: 'gap-4 pb-4 md:(grid gap-y-0 pb-6)',
      itemsDetail: {
        attributes: '',
        container: 'mt-4 text-sm <md:hidden',
        grid: 'grid gap-2 md:gap-4',
        hiddenItemsCount: 'bg-grey-80 text-lg fw-bold',
        name: 'fw-bold'
      },
      notification: 'col-span-2 mb-4',
      orderDate: 'fw-bold',
      viewOrderDetailsButton: ''
    },
    brandStyles: {
      'grid-cols': {
        sm: '14.75rem 1fr'
      }
    },
    image: {
      height: { sm: 134, lg: 270 },
      width: { sm: 134, lg: 270 },
    },
    itemsCount: {
      sm: 4,
      md: 4,
      lg: 3
    },
    notificationVariant: 'center'
  },
  onErrorNavigateTo: '/sign-in',
} satisfies RecursivePartial<AccountConfig>
