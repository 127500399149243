import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  brandClasses: {
    deliveryMethod: 'title-4 mb-1',
    newsletter: 'fw-medium',
    shippingMethodCode: 'col-span-2',
    shippingMethodDeliveryTime: 'c-grey-20 col-span-3 my-1 capitalize <md:(order-2 ml-10)'
  }

} satisfies CheckoutConfig['addShippingAddress']
