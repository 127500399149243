import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    base: 'rounded-full px-4 py-3 text-sm lh-1 ring ring-inset i-flex duration peer-focus-visible:outline-auto',
    disabled: 'c-grey-30 ring-grey-40',
    selected: 'ring-transparent bg-brand-1 c-white',
    unselected: 'ring-grey-40 hover:ring-grey-10 c-grey-10 bg-white'
  }
} satisfies VfConfig['toggleToken']
