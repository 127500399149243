<template>
  <input
    v-model="model"
    :aria-describedby="ariaDescribedby"
    data-test-id="base-input"
    @change="$emit('change', ($event?.target as HTMLInputElement)?.value)"
  >
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Identifies the element (or elements) that describes the object.
   * Needs to be defined explicitly as a prop so the dash can be kept
   * in the correct place for the input attribute
   */
  ariaDescribedby?: string
}>()

defineEmits<{ change: [value: string] }>()

const [model, modifiers] = defineModel<string | number>({
  set(value) {
    if (typeof value === 'string') {
      if (modifiers.capitalize)
        return value.charAt(0).toUpperCase() + value.slice(1)
      if (modifiers.uppercase)
        return value.toUpperCase()
      if (modifiers.lowercase)
        return value.toLowerCase()
    }

    return value
  }
})

defineExpose(getCurrentInstance()?.proxy!)
</script>
