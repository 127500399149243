import type { CheckoutConfig } from '#types/config/checkout'

export default {
  brandClasses: {
    ctas: 'w-full pt-2 fw-bold underlined',
    header: 'fw-bold',
    logo: 'mr-2 shrink-0',
    wrapper: 'max-w-full space-x-1',
  }
} satisfies CheckoutConfig['loyalty']
