import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  }
} satisfies RecursivePartial<DialogConfig['storeChanged']>
