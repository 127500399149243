import type { ApplePayButton } from '#types/components/checkout/applePayButton'
import type { RecursivePartial } from '#types/utils'

export default {
  icon: {
    height: 24,
    width: 64
  },
  size: 'md',
  variant: 'primary'
} satisfies RecursivePartial<ApplePayButton>
