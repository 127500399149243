import type { FilterConfig } from '#types/config/components/filter'

export default {
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  }
} satisfies FilterConfig['pickup']
