import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    content: {
      x: 'w-100 lg:w-84'
    }
  }
} satisfies RecursivePartial<VfConfig['panel']>
