import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    layout: 'text',
    logo: {
      type: 'inline'
    },
    text: {
      color: 'monochrome',
      size: 13
    }
  },
  showAmount: {
    product: false,
    cart: true,
    payment: true
  }
} satisfies RecursivePartial<PaypalConfig['payments']>
