import componentsCmsBannerOverlay from "/opt/build/repo/domains/content/config/app/components/cms/bannerOverlay"
import componentsCmsBannerThin from "/opt/build/repo/domains/content/config/app/components/cms/bannerThin"
import componentsCmsBlockBelow from "/opt/build/repo/domains/content/config/app/components/cms/blockBelow"
import componentsCmsCollection from "/opt/build/repo/domains/content/config/app/components/cms/collection"
import componentsCmsHero from "/opt/build/repo/domains/content/config/app/components/cms/hero"
import componentsCmsImageBanner from "/opt/build/repo/domains/content/config/app/components/cms/imageBanner"
import componentsCmsLinkedCard from "/opt/build/repo/domains/content/config/app/components/cms/linkedCard"
import componentsCmsMegaMenu from "/opt/build/repo/domains/content/config/app/components/cms/megaMenu"
import componentsCmsMegaMenuL1Desktop from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL1Desktop"
import componentsCmsMegaMenuL1Mobile from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL1Mobile"
import componentsCmsMegaMenuL2Desktop from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL2Desktop"
import componentsCmsMegaMenuL2Mobile from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL2Mobile"
import componentsCmsMegaMenuL3Desktop from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL3Desktop"
import componentsCmsMegaMenuL3Mobile from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuL3Mobile"
import componentsCmsMegaMenuVisualSelector from "/opt/build/repo/domains/content/config/app/components/cms/megaMenuVisualSelector"
import componentsCmsProductRecommendations from "/opt/build/repo/domains/content/config/app/components/cms/productRecommendations"
import componentsCmsPromoBar from "/opt/build/repo/domains/content/config/app/components/cms/promoBar"
import componentsCmsSecondaryNav from "/opt/build/repo/domains/content/config/app/components/cms/secondaryNav"
import componentsCmsSection from "/opt/build/repo/domains/content/config/app/components/cms/section"
import componentsCmsSharedButton from "/opt/build/repo/domains/content/config/app/components/cms/sharedButton"
import pagesCms from "/opt/build/repo/domains/content/config/app/pages/cms"
    
export default {"components":{"cms":{"bannerOverlay":componentsCmsBannerOverlay,"bannerThin":componentsCmsBannerThin,"blockBelow":componentsCmsBlockBelow,"collection":componentsCmsCollection,"hero":componentsCmsHero,"imageBanner":componentsCmsImageBanner,"linkedCard":componentsCmsLinkedCard,"megaMenu":componentsCmsMegaMenu,"megaMenuL1Desktop":componentsCmsMegaMenuL1Desktop,"megaMenuL1Mobile":componentsCmsMegaMenuL1Mobile,"megaMenuL2Desktop":componentsCmsMegaMenuL2Desktop,"megaMenuL2Mobile":componentsCmsMegaMenuL2Mobile,"megaMenuL3Desktop":componentsCmsMegaMenuL3Desktop,"megaMenuL3Mobile":componentsCmsMegaMenuL3Mobile,"megaMenuVisualSelector":componentsCmsMegaMenuVisualSelector,"productRecommendations":componentsCmsProductRecommendations,"promoBar":componentsCmsPromoBar,"secondaryNav":componentsCmsSecondaryNav,"section":componentsCmsSection,"sharedButton":componentsCmsSharedButton}},"pages":{"cms":pagesCms}}
