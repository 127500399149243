import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    firstStep: 'c-grey-30 hover:!c-grey-10',
    item: 'max-w-1/2 shrink-0 truncate c-grey-30 hover:!c-grey-10',
    separator: 'c-grey-30'
  }
} satisfies RecursivePartial<VfConfig['breadcrumbs']>
