<template>
  <span :id :class="$props.class" :data-test-id="dataTestId">
    <span aria-hidden="true" class="block truncate" :class="{ 'c-grey-30': disabled }">
      {{ labelFormatter?.(currentOption) || currentOption?.label || placeholder }}
    </span>
    <select
      ref="select"
      v-model="model"
      v-bind="$attrs"
      class="absolute-0 full op-0"
      :class="{ 'cursor-pointer': !disabled }"
      data-test-id="base-select"
      :disabled="disabled"
      @change="$emit('change', ($event?.target as HTMLSelectElement)?.value)"
    >
      <option v-if="placeholder" value="">{{ placeholder }}</option>
      <option
        v-for="({ value, label, disabled: optionDisabled }) in normalizedOptions"
        :key="label"
        :disabled="optionDisabled"
        :selected="model === value"
        :value
      >
        {{ label }}
      </option>
    </select>
  </span>
</template>

<script lang="ts" setup>
import type { SelectOption } from '#types/components/base/select'
import type { CSSClass } from '#types/common'

const props = defineProps<{
  options: (string | SelectOption)[] | (() => Promise<SelectOption[]>)
  placeholder?: string
  disabled?: boolean
  labelFormatter?: (option?: SelectOption) => string
  id?: string
  class?: CSSClass
  dataTestId?: string
}>()

defineEmits<{ change: [value: string | number | object] }>()

const model = defineModel<string | number | object>()

const select = ref<HTMLSelectElement>()
const lazyOptions = ref<SelectOption[]>([])

/**
 * Option items are normalized to always be an array of objects of type SelectOption for a more convenient DX.
 */
const normalizedOptions = computed<SelectOption[]>(() => isFunction(props.options)
  ? lazyOptions.value
  : props.options.map((option) => isObject(option) ? option : { value: option, label: option })
)
const currentOption = computed(() => normalizedOptions.value.find(({ value }) => value === model.value))

const handleFetchOptions = () => {
  if (!isFunction(props.options)) return
  props.options().then((_list) => lazyOptions.value = _list)
}

onMounted(() => {
  handleFetchOptions()

  let timer: ReturnType<typeof setTimeout>
  const checkValue = () => {
    if (select.value?.value && (select.value?.value !== currentOption.value?.value))
      model.value = select.value?.value
    timer = setTimeout(checkValue, 500)
  }

  timer = setTimeout(checkValue, 500)

  onUnmounted(() => {
    clearTimeout(timer)
  })
})
</script>
