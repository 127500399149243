import type { CartOrderSummaryConfig } from '#types/config/components/cart/orderSummary'
import type { RecursivePartial } from '#types/utils'

export default {
  loyalty: {
    showEachRewardInSummary: false,
    showLoyaltyOnEmptyCart: true,
    loyaltyUsesVouchers: false
  },
  showCheckoutCta: true,
  stickyCtas: {
    enabled: true
  },
} satisfies RecursivePartial<CartOrderSummaryConfig>
