import type { ApiConfig } from '#types/config/api'

const pickupPayload = {
  or: {
    bopis_enabled: {
      eq: '1'
    },
    sts_enabled: {
      eq: '1'
    }
  }
}

export default {
  api: {
    limit: 20
  },
  storeIdFields: {
    'default': 'storenumber',
    'en-ca': 'enterprise_store_identifier',
    'en-us': 'enterprise_store_identifier',
    'es-us': 'enterprise_store_identifier'
  },
  pickupPayloads: {
    'default': {},
    'en-ca': pickupPayload,
    'en-us': pickupPayload,
    'es-us': pickupPayload
  }
} satisfies ApiConfig['brandify']
