import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    heading: 'title-2',
    seeResultsCta: 'w-full',
    resetCta: 'px-2 text-sm underlined',
  },
  dialogOptions: { type: 'panel' }
} satisfies RecursivePartial<DialogConfig['filters']>
