import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    iconWrapper: 'lg:gap-4',
    logo: 'h-10',
    primaryNavigation: 'gap-3 py-4 lg:py-2',
    utilityNavigation: 'gap-4'
  },
  cartIconSize: 'lg',
  excludeStickyRoute: ['c-slug', 'search']
} satisfies RecursivePartial<VfConfig['header']>
