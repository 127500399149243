import type { SignInConfig } from '#types/config/components/form/signIn'

export default {
  brandClasses: {
    forgotPassword: 'mt-2',
    heading: 'subtitle-1',
    helpText: 'text-xs c-grey-20',
    signUp: 'mt-6 text-sm',
    submitButton: 'mt-6 w-full',
    wrapper: 'space-y-2'
  }
} satisfies SignInConfig
