<template>
  <iframe data-test-id="base-youtube" :src />
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  muted?: boolean
}>()

const src = computed(() => {
  const url = new URL(`https://www.youtube.com/embed/${props.id}`)

  if (props.autoplay) url.searchParams.set('autoplay', '1')
  if (props.autoplay || props.muted) url.searchParams.set('mute', '1')
  if (props.loop) url.searchParams.set('loop', `1&playlist=${props.id}`)
  if (!props.controls) url.searchParams.set('controls', '0')

  return url.toString()
})
</script>
