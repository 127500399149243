import type { BopisConfig } from '#types/config/components/bopis'
import type { RecursivePartial } from '#types/utils'

export default {
  storeDetails: {
    brandClasses: {
      addressLineSection: 'underlined text-sm mt-6 lg:mt-12',
      columnWrapper: 'lg:cols-5',
      locationSection: 'col-span-2',
      storeSection: 'fw-bold',
      storeProductTitle: 'md:mb-6',
      storeInstruction: 'fw-bold'
    },
    image: {
      width: 136,
      height: 136
    }
  }
} satisfies RecursivePartial<BopisConfig>
