import type { RecursivePartial } from '#types/utils'
import type { CheckoutConfig } from '#types/config/pages/checkout'

export default {
  contactFieldsOrder: ['email', 'phone'],
  hideLoyaltyDivider: false,
  isTaxRefundable: true,
  isFeeRefundable: true,
  legalCheckboxStep: 'shipping',
  loyaltyType: [
    'LOYALTY_POINTS',
  ],
  showCheckoutFooter: true,
  showTotalBeforeRewards: false,
} satisfies RecursivePartial<CheckoutConfig>
