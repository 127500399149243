<template>
  <component
    :is="tag || 'div'"
    v-style="state.style"
    :class="state.classes"
    data-test-id="base-grid"
    style="grid-auto-rows: min-content; grid-auto-flow: dense"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { GridCell, GridGap } from '#types/components/base/grid'

const props = defineProps<{
  /**
   * Root component tag can be div or span
   */
  tag?: 'div' | 'span'
  /**
   * The Number of columns in the grid can be specified per breakpoint
   */
  cols?: GridCell
  /**
   * The Number of rows in the grid can be specified per breakpoint
   */
  rows?: GridCell
  /**
   * The Size of the gap for both cols and rows based on spacing value from DS, can be specified per breakpoint
   */
  gap?: GridGap
  /**
   * The Size of the cols gap based on spacing value from DS, can be specified per breakpoint
   */
  gapX?: GridGap
  /**
   * The Size of the rows gap based on spacing value from DS, can be specified per breakpoint
   */
  gapY?: GridGap
}>()

const config = useAppConfig().components.base?.grid

const state = computed(() => {
  const { cols, rows, gap, gapX, gapY } = mapObject(props, (v) => isObject(v) ? v : { sm: v })
  return {
    style: filterObject({
      'grid-cols': filterObject(mapObject(cols, (v) => v ? `repeat(${v},minmax(0,1fr))` : null)),
      'grid-rows': filterObject(mapObject(rows, (v) => v ? `repeat(${v},minmax(0,1fr))` : null)),
      'gap': filterObject(mapObject(gap, (v) => v ? config.gap[v] : null)),
      'gap-x': filterObject(mapObject(gapX, (v) => v ? config.gap[v] : null)),
      'gap-y': filterObject(mapObject(gapY, (v) => v ? config.gap[v] : null))
    }, (v) => Object.keys(v).length > 0),
    // Adding grid class only on breakpoints that have defined columns for optimal performance
    classes: getResponsiveClasses(cols, 'grid', true)
  }
})
</script>
