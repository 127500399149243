import type { VfConfig } from '#types/config/components/vf'

export default {
  closeIconSize: {
    sm: 'sm',
    md: 'md',
    lg: 'md'
  },
  iconSize: {
    sm: 'lg',
    md: 'lg',
    lg: 'lg'
  },
  closeStyle: 'icon'
} satisfies VfConfig['notification']
