import type { PagesConfig } from '#types/config/pages'

export default {
  brandClasses: {
    breadcrumbs: 'mt-6',
    contactFormTitle: 'mb-6 mt-10 title-2',
    layout: 'my-6 flex pb-3 <lg:flex-col-reverse gap-4',
    sectionHeading: 'text-center title-1',
    sidebar: 'w-65 shrink-0 print:hidden',
    tiles: 'grid gap-2 lg:cols-4'
  },
  sidebar: {
    brandClasses: {
      accordion: 'b-t-0',
      accordionSpan: 'flex items-center hover:underlined',
      listItem: 'mb-4',
      sidebarLink: ''
    },
    chevronSize: 'md',
  },
  titleBelowBreadcrumb: false,
  showEmployeeContactUs: false,
  hasFaqPage: true,
  hideSidebarPaths: ['/help', '/help/all-topics'],
} satisfies PagesConfig['help']
