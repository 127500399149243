import type { RecursivePartial } from '#types/utils'
import type { RobisFormConfig } from '#types/config/components/form/robis'

export default {
  brandClasses: {
    form: 'f-col gap-6 lg:flex-row',
    ctaWrapper: 'lg:w-1/2',
    fieldsWrapper: 'f-col gap-6 lg:flex-row',
  },
  separateNameFields: true,
} satisfies RecursivePartial<RobisFormConfig>
