import type { CheckoutConfig } from '#types/config/checkout'

export default {
  cardNumberLengths: [16, 19],
  cardPinLengths: [4, 8],
  dialogOptions: { type: 'modal' },
  minDigitsForPin: 16,
  showPin: {
    requiredLocales: [
      'en-us',
      'en-ca',
      'es-us',
      'fr-ca'
    ]
  }
} satisfies CheckoutConfig['giftCard']
