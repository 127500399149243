import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rewardsPreviewTileButton: 'px-3 py-2 text-xs vf-button-primary-inverse vf-button',
    rewardsPreviewTilePopoverContent: 'z-overlay min-w-xs pt-6',
    rewardsPreviewTilePopoverContentInner: 'rounded-lg bg-white p-4 c-grey-10 shadow-xs -mr-4'
  }
} satisfies RecursivePartial<VfConfig['loyalty']>
