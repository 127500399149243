import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  newsletter: {
    checkboxSize: 'md',
    buttonSize: { sm: 'sm', md: 'xs' }
  },
  showCustomizationContent: false,
  showNewsletterForAuthenticatedUsers: false
} satisfies RecursivePartial<VfConfig['footer']>
