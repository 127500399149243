import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  toggleDefaults: {
    NORA: ['us', 'in'],
    EMEA: ['eu', 'cm']
  },
  sizeChart: {
    brandClasses: {
      footerContainer: 'mt-4 md:mt-8 ',
      footerTitle: 'title-4',
      footerDescription: '<md:text-sm'
    }
  },
  fits: {
    brandClasses: {
      title: 'fw-bold'
    }
  },
  measurements: {
    number: true,
    brandClasses: {
      type: 'title-3',
      number: 'bg-brand-2',
      title: 'fw-bold',
      imgTitle: 'title-5',
    },
    footer: false
  }
} satisfies RecursivePartial<DialogConfig['sizeChart']>
