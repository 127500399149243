<template>
  <base-ticker v-if="autoplay" ref="tickerRef" :interval="interval" @change="() => scroll?.next?.()">
    <base-scroll ref="scroll" v-bind="$attrs" @mouseout="tickerRef?.pause" @mouseover="tickerRef?.resume">
      <template v-for="_, name in $slots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </base-scroll>
  </base-ticker>
  <base-scroll v-else ref="scroll" v-bind="$attrs">
    <template v-for="_, name in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </base-scroll>
</template>

<script lang="ts" setup>
import type { BaseScroll as Scroll, BaseTicker as Ticker } from '#components'

defineOptions({
  inheritAttrs: false
})
defineProps<{
  autoplay?: boolean
  interval?: number
}>()
defineSlots<{
  prev: (props: { active: boolean, action: () => void }) => void
  default: (props: { active: number, activeItem: number, action: (i: number) => void }) => void
  next: (props: { active: boolean, action: () => void }) => void
  pagination: (props: { active: number, activeItem: number, action: (i: number) => void, pages: number }) => void
  bottom: () => void
}>()

const tickerRef = ref<InstanceType<typeof Ticker>>()
const scroll = ref<InstanceType<typeof Scroll>>()

defineExpose({
  el: computed(() => scroll.value?.$el),
  scroll: computed(() => scroll.value)
})
</script>
