import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    lastStep: 'truncate',
    item: 'max-w-1/2 shrink-0 truncate',
    wrapper: 'flex items-center gap-x-2'
  },
  homeLink: '/',
  iconSize: 'lg',
  linkVariant: 'quieter',
  showFirstStepIcon: true
} satisfies VfConfig['breadcrumbs']
