import type { RecursivePartial } from '#types/utils'
import type { CheckboxConfig } from '#types/config/components/vf/checkbox'

export default {
  brandClasses: {
    base: {
      default: 'ring-grey-40',
      disabled: 'bg-grey-90 ring-grey-50',
      error: 'ring-red-30',
      selected: 'bg-brand-1 ring-brand-1',
      selectedDisabled: 'bg-grey-50 ring-grey-50'
    },
    inverse: {
      default: 'ring-grey-60',
      disabled: 'bg-grey-20 ring-grey-50',
      error: '!ring-red-60',
      selected: '',
      selectedDisabled: ''
    },
    md: {
      label: 'text-base',
      icon: '',
      wrapper: 'gap-x-4'
    },
    sm: {
      label: 'text-base',
      icon: 'mt-1',
      wrapper: 'gap-x-2'
    }
  }
} satisfies RecursivePartial<CheckboxConfig>
