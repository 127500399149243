import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  newsletter: {
    checkboxSize: 'sm',
    buttonSize: 'sm'
  },
  columns: {
    brandClasses: 'md:mx-4'
  },
  showCustomizationContent: true,
  showLoyaltyContent: true,
  showNewsletterForAuthenticatedUsers: true,
  showSocialMediaLinks: true,
  showStoreLocator: true
} satisfies RecursivePartial<VfConfig['footer']>
