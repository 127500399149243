import type { GiftCardConfig } from '#types/config/components/form/giftCard'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    amountThreshold: 'c-grey-20'
  },
  messageMaxLength: 254,
  showBadge: true,
  swatches: {
    collapseThreshold: 24,
    showCollapseIcon: true,
    size: { sm: 'md', lg: 'lg' },
    variant: 'round'
  }
} satisfies RecursivePartial<GiftCardConfig>
