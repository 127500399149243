<template>
  <slot />
</template>

<script lang="ts" setup>
import {
  useHydrateOnInteraction,
  useHydrateWhenIdle,
  useHydrateWhenVisible,
  useLazyHydration
} from 'vue3-lazy-hydration'
import type { HydrationMode } from '#types/hydration'

const props = withDefaults(defineProps<{
  when?: HydrationMode
  interaction?: keyof HTMLElementEventMap | (keyof HTMLElementEventMap)[]
  observerOptions?: IntersectionObserverInit
}>(), {
  interaction: () => ['touchstart', 'focus']
})

const emit = defineEmits<{ hydrated: [] }>()

const result = useLazyHydration()

if (result.willPerformHydration) {
  result.onHydrated(() => emit('hydrated'))

  if (props.when === 'idle')
    useHydrateWhenIdle(result)

  if (props.when === 'visible')
    useHydrateWhenVisible(result, props.observerOptions)

  if (props.when === 'interaction')
    useHydrateOnInteraction(result, props.interaction && [props.interaction].flat())
}
</script>
