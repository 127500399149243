import type { FeatureFlags } from '#core/server/types/featureFlags'

// We assume the config is always available after the app started.
export default () => toReactive(useState('featureFlags', () => ({} as FeatureFlags)))

export const useLocalFeatureFlags = () => useState<Partial<FeatureFlags>>('localFeatureFlags')

export const useFeatureFlagsPassthroughHeader = () => useState('featureFlagsPassthroughHeader', () => '')

export const useExperiments = () =>
  toReactive(useState('experiments', () => ({} as Partial<{ [key in keyof FeatureFlags]: any }>)))
