import type { RecursivePartial } from '#types/utils'
import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  }
} satisfies RecursivePartial<CheckoutConfig['shippingAndDelivery']>
