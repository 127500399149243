import type { ProductListConfig } from '#types/config/components/product/list'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCtaIcon: 'ml-1',
    innerGridWrapper: 'grow pb-16',
    noResults: 'mb-14 mt-12 text-center lg:mt-10 title-2',
    gridSizeSelector: 'flex justify-end gap-2 py-4 <lg:hidden container',
    outerGrid: 'container flex gap-2 pt-6',
    pagination: 'mt-12',
    sideFilters: 'container',
    stickyHeader: 'children:(b-grey-70 b-b)',
    stickyHeaderStuck: 'shadow-bottom children:b-transparent'
  },
  dialogOptions: { type: 'panel' },
  eagerLoadedCards: 12,
  filterAndSortCta: {
    size: 'xs',
    variant: 'tertiary'
  },
  filtersPanelPosition: 'left',
  grid: {
    cols: {
      sm: 2,
      md: 3,
      lg: 4
    },
    gaps: {
      x: 'sm',
      y: {
        sm: 'md',
        md: 'lg',
        lg: 'xl'
      }
    }
  },
  hydrationMode: 'interaction',
  pickupFilterAboveList: true,
  productsPerPage: 48,
  sideFilterOpenFacets: 2
} satisfies RecursivePartial<ProductListConfig>
