<template>
  <custom-nuxt-link
    v-bind="{ ...props, to, target, external }"
    data-test-id="base-link"
    @click="hooks.callHook('link:clicked')"
  >
    <slot />
  </custom-nuxt-link>
</template>

<script lang="ts" setup>
import type { HookResult } from '@nuxt/schema'
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw
  target?: '_blank' | '_self' | null
  prefetch?: boolean
  ariaCurrentValue?: 'location' | 'false' | 'true' | 'page' | 'time' | 'date' | 'step' | undefined
  noRel?: boolean
}>(), {
  prefetch: false,
  // type-casting is used due to router-link TS bug that doesn't allow null value to be passed
  ariaCurrentValue: null as unknown as undefined
})

declare module '#app' {
  interface RuntimeNuxtHooks {
    'link:clicked': () => HookResult
  }
}

const route = useRoute()
const { hooks } = useNuxtApp()

const to = computed(() => {
  // Works around a weird behaviour in router-link which breaks anchor links for us.
  // When `to` prop values that are strings and are not full URLs (e.g. anchors, as in our case)
  // are parsed and normalised, they're appended only to the path of the current location,
  // which causes the current query string, if any, to be dropped
  if (typeof props.to === 'string' && props.to.startsWith('#'))
    return `${route.fullPath.split('#')[0]}${props.to}`

  return useLocalisedRoute(props.to!)
})
const external = computed(() => !!(typeof props.to === 'string' && props.to.match(/^https?:\/\//)))
const target = computed(() => props.target || (external.value ? '_blank' : undefined))
const CustomNuxtLink = defineNuxtLink({ trailingSlash: external.value ? undefined : 'remove' })
</script>
