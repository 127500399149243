import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  brandClasses: {
    ctasWrapper: 'py-6 space-y-4',
    eSpotAboveAccordions: 'b-grey-70 pb-4 not-only-child:(b-t pt-4) dark:b-grey-30',
    eSpotLazyAboveFooter: '',
    eSpotLazyBelowGallery: '',
    headingProductName: 'elevate-body-1 fw-bold',
    klarnaPayments: 'mt-4',
    variantSelectionWrapper: 'mt-8 space-y-8'
  },
  details: {
    ctaVariant: 'secondary',
    favoriteCta: {
      variantSelected: 'secondary'
    }
  },
  disableGroupBy: true,
  showAddToCartCta: false,
  swatches: {
    size: {
      sm: 'md',
      lg: 'sm'
    },
    variant: 'rectangle'
  }
} satisfies RecursivePartial<PdpConfig>
