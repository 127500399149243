import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    tabs: 'pb-8 md:pb-10'
  },
  sizeChart: {
    brandClasses: {
      footerTitle: 'subtitle-5 md:subtitle-3',
      footerDescription: 'text-sm fw-light'
    }
  },
  measurements: {
    brandClasses: {
      type: 'subtitle-3 uppercase',
    }
  }
} satisfies RecursivePartial<DialogConfig['sizeChart']>
