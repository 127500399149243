import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  presets: {
    size: {
      width: 94,
      height: 94
    }
  }
} satisfies CheckoutConfig['orderItem']
