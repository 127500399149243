import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils.js'

export default {
  brandClasses: {
    title: {
      md: 'subtitle-7',
      sm: 'subtitle-5'
    }
  }
} satisfies RecursivePartial<VfConfig['accordion']>
