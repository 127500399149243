import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    color: 'white',
    height: 48,
    label: 'pay'
  }
} satisfies RecursivePartial<PaypalConfig['button']>
