import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  closeIconSize: 'md',
  ignoreSelectors: [
    '#nuanMessagingFrame',
    '#onetrust-banner-sdk'
  ]
} satisfies RecursivePartial<VfConfig['dialog']>
