import type { ProductConfig } from '#types/config/components/product'

export default {
  bulletin: {
    brandClasses: {
      list: 'grid list-disc gap-4 cols-2'
    }
  },
  modalGrid: {
    brandClasses: {
      button: 'w-full hover:underlined',
      list: 'flex gap-x-2 gap-y-4 wrap',
      listItem: 'w-22',
      picture: 'mb-4 mt-3 aspect-square w-16'
    },
    dialogOptions: { type: 'modal' },
  }
} satisfies ProductConfig['accordions']
