import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCtaIcon: 'ml-2',
    noResults: 'elevate-title-3 pb-16 pt-12 text-center lg:pt-16',
    outerGrid: 'container flex gap-2 <lg:pt-4',
    outerGridWrapper: 'bg-brand-6',
    stickyHeader: 'b-grey-70 b-b',
    stickyHeaderStuck: ''
  },
  filterAndSortCta: {
    iconSize: 'md',
    size: 'tiny'
  },
  grid: {
    gaps: {
      x: 'xs',
      y: 'xs'
    }
  },
  scrollToElementOptions: { offset: 0 }
} satisfies RecursivePartial<ProductConfig['list']>
