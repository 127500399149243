<template>
  <form ref="formRef" data-test-id="base-form" novalidate @submit="onSubmit">
    <slot v-bind="{ errors: fields, reset, validate, valid, onChange }" />
  </form>
</template>

<script lang="ts" setup>
import { FormContextKey } from './context'
import type { Field, Rule } from '#types/components/base/form'

const props = withDefaults(defineProps<{
  form: Record<string, any>
  rules?: Record<string, Rule>
  validateOn?: 'change' | 'submit'
  resetOn?: 'unmount' | 'deactivate'
  scrollToError?: boolean
}>(), {
  rules: () => ({}),
  validateOn: 'change',
  resetOn: 'deactivate',
  scrollToError: true
})

const emit = defineEmits<{
  submit: [e: SubmitEvent]
  change: []
  reset: [payload: typeof initFormValue]
}>()

defineSlots<{
  default: (props: {
    errors: Readonly<Record<string, Field>>
    reset: () => void
    validate: () => void
    onChange: () => void
    valid: boolean
  }) => any
}>()

const pipe = (validators: Rule) =>
  (value, data, key): Field => {
    let message
    const messages = {}
    if (Array.isArray(validators)) {
      message = validators.map((validator) => validator(value, data, key)).find((e) => e)
    }
    else if (typeof validators === 'function') {
      message = validators(value, data, key)
    }
    else {
      message = Object.entries(validators)
        .map(([name, validator]) => {
          const msg = validator(value, data, key)
          messages[name] = msg
          return msg
        })
        .find((e) => e)
    }
    const invalid = !message && !value ? undefined : !!message

    return {
      messages,
      message: message || '',
      invalid,
      validated: true,
      attrs: {
        invalid,
        onChange
      }
    }
  }

const copyRule = (rule) => Array.isArray(rule) ? [rule].flat() : rule

const valid = ref(false)
const formRef = ref()
const fieldsOrder: string[] = []

const initFormValue = readonly({ ...props.form })

const generateField = (): Field => ({
  messages: {},
  message: '',
  invalid: undefined,
  firstInvalid: false,
  validated: false,
  attrs: {
    invalid: undefined,
    onChange
  }
})
const generateFields = (): Record<string, Field> => Object.keys(props.form).reduce((acc, key) => ({
  ...acc,
  [key]: generateField()
}), {})
const fields = ref(generateFields())
const rules = reactive({
  ...props.rules
})

const stamp = () => ({ ...props.form })
let prev = stamp()
let cur = stamp()

const validate = (force?: boolean, enableFirstInvalid = true) => {
  cur = stamp()
  const fieldsEntries: [string, Field][] = Object.entries(rules).map(([key, validator]) => [
    key,
    (force || cur[key] !== prev[key])
      ? pipe(validator)(cur[key], readonly(cur), key)
      : fields.value[key]
  ])

  if (force && enableFirstInvalid) {
    // Find first invalid field
    const firstInvalid = fieldsOrder.find((name) =>
      fieldsEntries.some(([key, field]) => key === name && field.invalid))

    // Set firstInvalid flag
    fieldsEntries.forEach(([key, field]) => field.firstInvalid = key === firstInvalid)
  }

  fields.value = Object.fromEntries(fieldsEntries)

  prev = stamp()

  valid.value
    = Object.values(fields.value).every(({ validated }) => validated)
    && Object.values(fields.value).every((error) => !error.message)

  return valid.value
}

const invalidate = (key: string, message: string, firstInvalid?: boolean) => {
  const field = fields.value[key]

  if (field) {
    field.invalid = true
    field.message = message
    field.attrs.invalid = true

    if (firstInvalid)
      field.firstInvalid = true
  }
}

const reset = () => {
  fields.value = generateFields()
  emit('reset', initFormValue)
  return readonly(initFormValue)
}

const onSubmit = async (e) => {
  e.preventDefault()
  if (!validate(true)) return
  emit('submit', e)
}

function onChange() {
  if (props.validateOn === 'change') validate()
  emit('change')
}

if (props.resetOn === 'deactivate') onDeactivated(reset)
if (props.resetOn === 'unmount') onUnmounted(reset)

provide(FormContextKey, {
  addRule({ name, rule }) {
    rules[name] = copyRule(rule)

    fields.value[name] ||= generateField()
  },
  removeRule(name) {
    delete rules[name]
  },
  getField(name) {
    fieldsOrder.push(name)
    return computed(() => readonly(fields.value[name]))
  },
  resetFirstInvalid(name) {
    fields.value[name].firstInvalid = false
  },
  scrollToError: props.scrollToError
})

defineExpose({
  valid,
  validate,
  invalidate,
  reset,
  fields
})
</script>
