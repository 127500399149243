import type { ApiConfig } from '#types/config/api'
import type { RecursivePartial } from '#types/utils'

export default {
  skipInventoryCheck: [
    'de-lu',
    'en-ie',
    'fr-lu',
    'nl-lu',
    'pt-pt'
  ]
} satisfies RecursivePartial<ApiConfig['brandify']>
