import type { CheckoutConfig } from '#types/config/checkout'
import type { RecursivePartial } from '#types/utils'

const defaultNORAConfig = {
  configuration: { intent: 'authorize' }
}

const defaultEMEAConfig = {
  intent: 'capture'
}

export default {
  'cs-cz': {
    url: 'https://www.paypal.com/cz/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'da-dk': {
    url: 'https://www.paypal.com/dk/webapps/mpp/personal?locale.x=da_DK',
    config: defaultEMEAConfig
  },
  'de-at': {
    url: 'https://www.paypal.com/at/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'de-be': {
    url: 'https://www.paypal.com/be/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'de-ch': {
    url: 'https://www.paypal.com/ch/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'de-de': {
    url: 'https://www.paypal.com/de/digital-wallet/how-paypal-works',
    config: defaultEMEAConfig
  },
  'de-lu': {
    url: 'https://www.paypal.com/lu/webapps/mpp/personal?locale.x=de_LU',
    config: defaultEMEAConfig
  },
  'en-ca': {
    url: 'https://www.paypal.com/ca/for-you/account/how-paypal-works',
    config: defaultNORAConfig
  },
  'en-ch': {
    url: 'https://www.paypal.com/ch/webapps/mpp/personal?locale.x=en_CH',
    config: defaultEMEAConfig
  },
  'en-cz': {
    url: 'https://www.paypal.com/cz/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'en-dk': {
    url: 'https://www.paypal.com/dk/webapps/mpp/personal?locale.x=en_DK',
    config: defaultEMEAConfig
  },
  'en-fi': {
    url: 'https://www.paypal.com/fi/webapps/mpp/personal?locale.x=en_FE',
    config: defaultEMEAConfig
  },
  'en-gb': {
    url: 'https://www.paypal.com/uk/webapps/mpp/how-paypal-works',
    config: defaultEMEAConfig
  },
  'en-ie': {
    url: 'https://www.paypal.com/ie/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'en-pt': {
    url: 'https://www.paypal.com/pt/webapps/mpp/personal?locale.x=en_PT',
    config: defaultEMEAConfig
  },
  'en-lu': {
    url: 'https://www.paypal.com/lu/webapps/mpp/personal?locale.x=en_LU',
    config: defaultEMEAConfig
  },
  'en-se': {
    url: 'https://www.paypal.com/se/webapps/mpp/personal?locale.x=en_SE',
    config: defaultEMEAConfig
  },
  'en-us': {
    url: 'https://www.paypal.com/us/digital-wallet/how-paypal-works',
    config: defaultNORAConfig
  },
  'es-us': {
    url: 'https://www.paypal.com/es/digital-wallet/how-paypal-works',
    config: defaultNORAConfig
  },
  'es-es': {
    url: 'https://www.paypal.com/es/digital-wallet/how-paypal-works',
    config: defaultEMEAConfig
  },
  'fr-be': {
    url: 'https://www.paypal.com/be/webapps/mpp/personal?locale.x=fr_BE',
    config: defaultEMEAConfig
  },
  'fr-ca': {
    url: 'https://www.paypal.com/ca/for-you/account/how-paypal-works?locale.x=fr_CA',
    config: defaultNORAConfig
  },
  'fr-ch': {
    url: 'https://www.paypal.com/ch/webapps/mpp/personal?locale.x=fr_CH',
    config: defaultEMEAConfig
  },
  'fr-fr': {
    url: 'https://www.paypal.com/fr/digital-wallet/how-paypal-works',
    config: defaultEMEAConfig
  },
  'fr-lu': {
    url: 'https://www.paypal.com/lu/webapps/mpp/personal?locale.x=fr_LU',
    config: defaultEMEAConfig
  },
  'it-ch': {
    url: 'https://www.paypal.com/ch/webapps/mpp/personal?locale.x=it_CH',
    config: defaultEMEAConfig
  },
  'it-it': {
    url: 'https://www.paypal.com/it/digital-wallet/how-paypal-works',
    config: defaultEMEAConfig
  },
  'nl-be': {
    url: 'https://www.paypal.com/be/webapps/mpp/personal?locale.x=nl_BE',
    config: defaultEMEAConfig
  },
  'nl-lu': {
    url: 'https://www.paypal.com/lu/webapps/mpp/personal?locale.x=nl_LU',
    config: defaultEMEAConfig
  },
  'nl-nl': {
    url: 'https://www.paypal.com/nl/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'pt-pt': {
    url: 'https://www.paypal.com/pt/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'pl-pl': {
    url: 'https://www.paypal.com/pl/webapps/mpp/personal',
    config: defaultEMEAConfig
  },
  'sv-fi': {
    url: 'https://www.paypal.com/fi/webapps/mpp/personal?locale.x=sv_FE',
    config: defaultEMEAConfig
  },
  'sv-se': {
    url: 'https://www.paypal.com/se/webapps/mpp/personal?locale.x=sv_SE',
    config: defaultEMEAConfig
  },
} satisfies RecursivePartial<CheckoutConfig['paypal']>
