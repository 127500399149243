import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    heading: 'title-4'
  },
  guards: {
    registered: { action: 'block' }
  }
} satisfies RecursivePartial<DialogConfig['simplifiedEnrollment']>
