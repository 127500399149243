import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    header: 'fw-bold bg-grey-90 uppercase',
    row: 'px-5 py-3',
    th: 'b b-grey-70',
    thSticky: 'before:(absolute b b-grey-70 content-empty -inset-px)',
    thXStuck: 'b-r b-grey-50',
    thYStuck: 'b-b b-grey-50',
    td: 'b b-grey-70',
    tdSticky: 'before:(absolute b b-grey-70 content-empty -inset-px)',
    tdStuck: 'b-r b-grey-50'
  }
} satisfies VfConfig['table']
