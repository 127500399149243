import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    badge: 'm-2 h-8 bg-white px-2 py-1',
    productNameInline: 'text-sm ~md:text-md',
    productNameModal: 'text-sm md:text-md',
    wrapperInline: 'lg:(absolute -top-8 -inset-x-2 z-3 bg-white pt-8 pb-4 px-2 shadow-md)'
  }
} satisfies RecursivePartial<DialogConfig['quickshop']>
