<template>
  <video
    v-if="active"
    ref="videoRef"
    v-style="{ 'aspect-ratio': ratio, 'bgi': poster }"
    :autoplay="autoplay ?? loop"
    class="full"
    :controls
    :crossorigin="activeTracks.length > 0 ? 'anonymous' : undefined"
    data-test-id="base-video"
    itemscope
    itemtype="https://schema.org/VideoObject"
    :loop
    :muted="autoplay || muted"
    playsinline
    poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    preload="metadata"
    style="background-position: center; background-repeat: no-repeat"
    :style="`background-size: ${fit}; object-fit: ${fit}`"
    @ended="onEnded"
    @pause="onPause"
    @play="onPlay"
    @playing="preventGtmEvent ||= true"
    @timeupdate="updateProgress"
  >
    <template v-for="(content, itemprop) in meta" :key="itemprop">
      <meta v-if="content" :content :itemprop>
    </template>
    <source v-for="(source, key) in sources" v-bind="source" :key>
    <track v-for="(track, key) in activeTracks" :key v-bind="track" label="Caption">
  </video>
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'
import type { BaseVideo as BaseVideoProps } from '#types/components/base/video'

const props = withDefaults(defineProps<BaseVideoProps>(), { controls: true, muted: true, fit: 'contain' })

const isMounted = ref(false)
const videoRef = ref<HTMLVideoElement>()
const active = ref(true)
const preventGtmEvent = ref(false)
const paused = ref(!props.autoplay)
const progress = ref(0)

const { $gtm, $viewport } = useNuxtApp()
const { breakpoints } = useAppConfig().ds

const src = computed(() => isObject(props.src) ? props.src : { sm: props.src } as Responsive)
const width = computed(() => isObject(props.width) ? props.width : { sm: props.width } as Responsive)
const height = computed(() => isObject(props.height) ? props.height : { sm: props.height } as Responsive)
const poster = computed(() => isObject(props.poster) ? props.poster : { sm: props.poster } as Responsive)
const activeTracks = computed(
  () => isMounted.value ? props.tracks?.[$viewport.breakpoint] || props.tracks?.sm || [] : []
)

const meta = computed(() => ({
  contentUrl: src.value.sm,
  thumbnailUrl: poster.value.sm,
  datePublished: props.meta?.date,
  uploadDate: props.meta?.date,
  description: props.meta?.description,
  name: props.meta?.title
}))

// Throw error if someone provided a data object with no sm breakpoint
if (!src.value.sm) log.error('Video: src missing')
if (!width.value.sm) log.info(`Video: No width for ${src.value.sm}`)
if (!height.value.sm) log.info(`Video: No height for ${src.value.sm}`)

const ratio = computed(() => width.value.sm && height.value.sm
  ? Array.from(Object.keys(width.value))
    .reduce((acc, key) => ({ ...acc, [key]: `${width.value[key]} / ${height.value[key]}` }), {} as Responsive)
  : undefined)

const sources = computed(() => [...new Set([src.value, width.value, height.value].flatMap(Object.keys))]
  .sort((a, b) => {
    const order = Object.keys(breakpoints).reverse()
    return order.indexOf(a) - order.indexOf(b)
  })
  .map((bp) => ({
    src: src.value?.[bp] || src.value?.sm,
    width: width.value?.[bp] || width.value?.sm,
    height: height.value?.[bp] || height.value?.sm,
    ...(breakpoints[bp] && { media: `(min-width:${breakpoints[bp]}px)` })
  }))
)

const onPlay = (e: Event) => {
  if (!preventGtmEvent.value && !props.autoplay)
    $gtm.push('video.onPlay', e.target, props.meta?.title)
  preventGtmEvent.value = false
  paused.value = false
}

const onPause = (e: Event) => {
  const target = e.target as HTMLVideoElement
  if (!target.seeking && !props.autoplay) {
    preventGtmEvent.value = false
    if (target.duration !== target.currentTime)
      $gtm.push('video.onPause', target, props.meta?.title)
  }
  paused.value = true
}

const onEnded = (e: Event) => {
  $gtm.push('video.onComplete', e.target, props.meta?.title)
}

const updateProgress = () => {
  const { currentTime, duration } = videoRef.value || {}

  progress.value = currentTime && duration ? (currentTime / duration) : 0
}

onMounted(() => {
  isMounted.value = true
  // Restarting video on breakpoint change
  watch(() => $viewport.breakpoint, () => {
    active.value = false
    nextTick(() => active.value = true)
  })
})

defineExpose({
  el: videoRef,
  play: () => videoRef.value?.play(),
  pause: () => videoRef.value?.pause(),
  toggle: () => videoRef.value?.paused ? videoRef.value.play() : videoRef.value?.pause(),
  progress: computed(() => progress.value),
  paused,
  src
})
</script>
