import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    title: {
      md: 'title-3'
    },
    toggle: {
      md: 'py-3',
      sm: 'py-1'
    },
    wrapper: 'b-t b-grey-70'
  },
  iconSize: {
    md: 'lg',
    sm: 'lg'
  }
} satisfies VfConfig['accordion']
