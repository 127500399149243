import type { PagesConfig } from '#types/config/pages'

export default {
  brandClasses: {
    formsWrapper: 'grid md:(cols-2 divide-x) divide-grey-50 <md:divide-y',
    heading: 'title-1'
  },
  orderNumberLengths: [8],
  orderStatusHelpText: {
    requiredLocales: [
      'en-gb',
      'nl-nl'
    ]
  },
  showOrderNumberToolTipImage: false,
  showLoginCta: false,
  showLoginForm: true,
} satisfies PagesConfig['orderStatus']
