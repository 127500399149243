import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    wrapper: 'pb-4 space-y-2'
  },
  dialogOptions: {
    type: 'modal'
  }
} satisfies ProductConfig['shippingMethods']
