import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    backButton: 'absolute top-0 mt-4 flex items-center md:-ml-6',
    wrapper: 'pt-7'
  },
  guards: {
    registered: { action: 'block' }
  }
} satisfies RecursivePartial<DialogConfig['forgotPassword']>
