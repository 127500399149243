import type { FiltersConfig } from '#types/config/components/dialog/filters'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    heading: 'elevate-title-3'
  },
  seeResultsCta: {
    size: 'xs'
  }
} satisfies RecursivePartial<FiltersConfig>
