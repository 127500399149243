import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    color: 'black',
    height: 48,
    label: 'pay',
    layout: 'horizontal',
    shape: 'pill',
  }
} satisfies RecursivePartial<PaypalConfig['button']>
