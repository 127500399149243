import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  bulletin: {
    brandClasses: {
      list: 'list-disc space-y-4'
    }
  },
  modalGrid: {
    brandClasses: {
      button: 'flex items-center gap-x-4 hover:underlined',
      list: 'space-y-4',
      listItem: 'w-full',
      picture: 'aspect-square w-15 dark:invert'
    }
  }
} satisfies RecursivePartial<ProductConfig['accordions']>
