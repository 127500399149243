<template>
  <picture
    ref="picture"
    data-test-id="base-picture"
    :itemscope="!!meta || undefined"
    :itemtype="meta ? 'https://schema.org/ImageObject' : undefined"
  >
    <meta v-for="(content, itemprop) in meta" :key="itemprop" :content="content?.toString()" :itemprop>
    <source v-for="(source, i) in sources" :key="i" v-bind="source">
    <img
      v-style:object-position="position"
      v-bind="propsImg"
      :alt
      :class="fit"
      :height="height.sm"
      :loading="lazy ? 'lazy' : undefined"
      :src="src.sm"
      :title="title || meta?.title"
      :width="width.sm"
    >
  </picture>
</template>

<script lang="ts" setup>
import type { ImgHTMLAttributes } from '#types/components/base/picture'
import type { Responsive } from '#types/common'
import type { MediaMeta } from '#types/seo'

const props = withDefaults(
  defineProps<{
    /**
     * Optional image alt attr
     */
    alt?: string
    /*
    * Specifies a fit type for the internal <img>
    *  */
    fit?: 'contain' | 'cover' | 'none'
    /**
     * Specifies height for various screen sizes
     */
    height?: Responsive<string | number> | string | number
    /**
     * Controls lazy load property of the image
     */
    lazy?: boolean
    /**
     * Optional SEO data for crawlers
     */
    meta?: MediaMeta
    /**
     * Specifies responsive object-position values for the internal <img>
     */
    position?: Responsive<string> | string
    /**
     * Container for passing additional props to internal image, such as class
     * you can also assign custom event to img by passing 'on' an object
     */
    propsImg?: ImgHTMLAttributes
    /**
     * Allows specifying src in a form of string or object with supported breakpoints
     */
    src: Responsive<string> | string
    /**
     * Optional image title attr
     */
    title?: string
    /**
     * Specifies width for various screen sizes
     */
    width?: Responsive<string | number> | string | number
  }>(),
  {
    lazy: true,
    alt: ''
  }
)

const { breakpoints } = useAppConfig().ds

const picture = ref<HTMLPictureElement>()

const src = computed(() => isObject(props.src) ? props.src : { sm: props.src })
const width = computed(() => isObject(props.width) ? props.width : { sm: props.width })
const height = computed(() => isObject(props.height) ? props.height : { sm: props.height })

// Throw error if someone provided a data object with no sm breakpoint
if (!src.value.sm) log.info('Picture: src missing')
if (!width.value.sm) log.info(`Picture: No width for ${src.value.sm}`)
if (!height.value.sm) log.info(`Picture: No height for ${src.value.sm}`)

const sources = computed(() => [...new Set([src.value, width.value, height.value].flatMap(Object.keys))]
  .filter((bp) => breakpoints[bp])
  .sort((a, b) => {
    const order = Object.keys(breakpoints).reverse()
    return order.indexOf(a) - order.indexOf(b)
  })
  .map((bp) => ({
    srcset: src.value?.[bp] || src.value?.sm,
    width: width.value?.[bp] || width.value?.sm,
    height: height.value?.[bp] || height.value?.sm,
    media: `(min-width:${breakpoints[bp]}px)`
  }))
)

defineExpose({
  picture
})
</script>
