import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    panel: {
      header: 'elevate-title-3'
    },
    modal: {
      header: 'elevate-title-3'
    }
  },
  closeIconSize: 'lg'
} satisfies RecursivePartial<VfConfig['dialog']>
